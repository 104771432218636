<template>
  <div class="personalDetail-container">
    <div class="personal-container">
      <div class="personal-info">
        <img
          v-if="detailInfo.icon"
          class="info-img"
          :src="detailInfo.icon"
          alt=""
        />
        <i v-else class="el-icon-user iconPeopleDetail"></i>
        <span class="info-name">{{ detailInfo.nickName }}</span>
        <span>欢迎使用帮客智能客服管理系统</span>
      </div>
      <div class="info-right">
        <div style="display: flex;justify-content: space-between;">
          <div class="personal-info" style="align-items: flex-start;">
            <span style="margin-top: 0" class="info-name">{{
              detailInfo.companyName
            }}</span>
            <span style="color: #333"
              >{{ detailInfo.departmentName }} |
              {{ detailInfo.serviceLevel || "--" }}</span
            >
            <p style="margin: 16px 0">
              <span>员工账号：{{ detailInfo.phone || "--" }}</span
              ><span style="margin-left: 16px"
                >入职时间：{{ detailInfo.joinTime || "--" }}</span
              >
            </p>
          </div>
          <el-tooltip
            v-if="adminId == userId"
            class="tip"
            content="编辑个人信息"
            placement="left"
            effect="light"
            :visible-arrow="false"
          >
            <i
              class="el-icon-edit-outline"
              @click="
                () => {
                  getDetails();
                  editVisible = true;
                }
              "
            ></i>
          </el-tooltip>
          <div v-else></div>
        </div>
        <div class="infoItem-container">
          <div class="info-item">
            <span>当月税前薪资(元)</span>
            <span class="item-num" style="color: #1890ff">{{
              (
                Number(currentMonth.basePay || 0) -
                Number(currentMonth.deductMoney || 0) +
                Number(currentMonth.bountyMoney || 0) +
                Number(currentMonth.subsidyMoney || 0) +
                Number(currentMonth.achievements || 0)
              ).toFixed(2)
            }}</span>
          </div>
          <div class="info-item">
            <span>当前底薪(元)</span>
            <span class="item-num">{{
              Number(currentMonth.basePay || 0).toFixed(2)
            }}</span>
          </div>
          <div class="info-item">
            <span>当月绩效提成(元)</span>
            <span class="item-num">{{
              Number(currentMonth.achievements || 0).toFixed(2)
            }}</span>
          </div>
          <div class="info-item">
            <!-- <span class="item-num" style="color: #52C419">{{Number(calculate.deductMoney || 0).toFixed(2)}}</span> -->
            <span>本月已有扣款(元)</span>
            <el-popover
              placement="left"
              title="扣款明细"
              width="auto"
              trigger="hover"
            >
              <table class="hover-table">
                <tr>
                  <th class="hover-th">扣款项目</th>
                  <th class="hover-th">扣款日期</th>
                  <th class="hover-th">扣款金额</th>
                </tr>
                <tr
                  v-for="(item, index) in priceShowDataCurrent.list1"
                  :key="index"
                >
                  <td class="hover-td">{{ item.wageName }}</td>
                  <td class="hover-td">{{ item.checkDate }}</td>
                  <td class="hover-td">
                    {{ Number(item.calculatePrice || 0).toFixed(2) }}元
                  </td>
                </tr>
                <tr
                  v-if="
                    !priceShowDataCurrent.list1 ||
                      !priceShowDataCurrent.list1.length
                  "
                >
                  <td colspan="3" style="text-align:center" class="hover-td">
                    暂无数据
                  </td>
                </tr>
              </table>

              <span
                slot="reference"
                class="item-num"
                style="color: #52C419;cursor: pointer;text-decoration: underline;"
                >{{ Number(currentMonth.deductMoney || 0).toFixed(2) }}元</span
              >
            </el-popover>
          </div>
          <div class="info-item">
            <!-- <span class="item-num" style="color: #e83636">{{calculate.bountyMoney || 0}}</span> -->
            <span>当月已有奖励(元)</span>
            <el-popover
              placement="left"
              title="奖励明细"
              width="auto"
              trigger="hover"
            >
              <table class="hover-table">
                <tr>
                  <th class="hover-th">奖励项目</th>
                  <th class="hover-th">奖励日期</th>
                  <th class="hover-th">奖励金额</th>
                </tr>
                <tr
                  v-for="(item, index) in priceShowDataCurrent.list2"
                  :key="index"
                >
                  <td class="hover-td">{{ item.wageName }}</td>
                  <td class="hover-td">{{ item.checkDate }}</td>
                  <td class="hover-td">
                    {{ Number(item.calculatePrice || 0).toFixed(2) }}元
                  </td>
                </tr>
                <tr
                  v-if="
                    !priceShowDataCurrent.list2 ||
                      !priceShowDataCurrent.list2.length
                  "
                >
                  <td colspan="3" style="text-align:center" class="hover-td">
                    暂无数据
                  </td>
                </tr>
              </table>

              <span
                slot="reference"
                class="item-num"
                style="color: #e83636;cursor: pointer;text-decoration: underline;"
                >{{ Number(currentMonth.bountyMoney || 0).toFixed(2) }}元</span
              >
            </el-popover>
          </div>
          <div class="info-item">
            <!-- <span class="item-num" style="color: #FD854F">{{calculate.subsidyMoney || 0}}</span> -->
            <span>补贴(元)</span>
            <el-popover
              placement="left"
              title="补贴明细"
              width="auto"
              trigger="hover"
            >
              <table class="hover-table">
                <tr>
                  <th class="hover-th">补贴项目</th>
                  <th class="hover-th">补贴日期</th>
                  <th class="hover-th">补贴金额</th>
                </tr>
                <tr
                  v-for="(item, index) in priceShowDataCurrent.list3"
                  :key="index"
                >
                  <td class="hover-td">{{ item.wageName }}</td>
                  <td class="hover-td">{{ item.checkDate }}</td>
                  <td class="hover-td">
                    {{ Number(item.calculatePrice || 0).toFixed(2) }}元
                  </td>
                </tr>
                <tr
                  v-if="
                    !priceShowDataCurrent.list3 ||
                      !priceShowDataCurrent.list3.length
                  "
                >
                  <td colspan="3" style="text-align:center" class="hover-td">
                    暂无数据
                  </td>
                </tr>
              </table>

              <span
                slot="reference"
                class="item-num"
                style="color: #FD854F;cursor: pointer;text-decoration: underline;"
                >{{ Number(currentMonth.subsidyMoney || 0).toFixed(2) }}元</span
              >
            </el-popover>
          </div>
          <!-- <div class="info-item-btn" @click="() => { calcuShow = !calcuShow }">
                        <span>收入预估测算</span>
                        <span>信息数据</span>
                    </div> -->
        </div>
      </div>
    </div>
    <div class="common-grey-line"></div>
    <div class="work-info">
      <span style="margin-left: 16px">工作信息：</span>
      <el-date-picker
        v-model="params.startDate"
        :picker-options="pickerOptions"
        class="common-screen-input"
        @change="changeDate"
        type="month"
        value-format="yyyy-MM-dd"
        placeholder="收入月份"
      ></el-date-picker>
    </div>
    <div class="common-grey-line"></div>
    <div style="display:flex">
      <div class="attendance">
        <div class="modu-title">考勤</div>
        <div class="attendance-top">
          <div class="attendance-item">
            <span>应出勤天数</span>
            <span class="item-name"
              >{{ (attendance && attendance.ShouldAttendanceDay) || 0 }}天</span
            >
          </div>
          <div class="attendance-item">
            <span>实际出勤天数</span>
            <span class="item-name"
              >{{ (attendance && attendance.actualDay) || 0 }}天</span
            >
          </div>
          <div class="attendance-item">
            <span>出勤率</span>
            <span class="item-name">{{
              (attendance && attendance.attendance) || 0
            }}</span>
          </div>
          <div class="attendance-item">
            <span>平均工时</span>
            <span class="item-name"
              >{{
                attendance &&
                Number(attendance.allWorkingTime) &&
                Number(attendance.actualDay)
                  ? (
                      Number(attendance.allWorkingTime) /
                      Number(attendance.actualDay)
                    ).toFixed(2)
                  : 0
              }}小时</span
            >
          </div>
          <div class="attendance-item">
            <span>出勤异常</span>
            <span class="item-name" style="color:#e83636"
              >{{ (attendance && attendance.anomalyDay) || 0 }}次</span
            >
          </div>
        </div>
        <!-- <div style="color:#333;margin-bottom: 16px">出勤异常列表</div>
                <table class="tableList">
                    <tr><th>交接班异常记录</th><th>当前班次时间</th></tr>
                    <tr><td>20210816客服交班异常</td><td>18:00-03:00</td></tr>
                    <tr><td>20210816客服交班异常</td><td>18:00-03:00</td></tr>
                    <tr><td>20210816客服交班异常</td><td>18:00-03:00</td></tr>
                    <tr><td>20210816客服交班异常</td><td>18:00-03:00</td></tr>
                </table> -->
      </div>
      <div class="serviceData">
        <div class="modu-title">客服服务数据</div>
        <div class="service-data">
          <div
            :class="
              serviceData.inviteComments
                ? 'serviceData-itemBig'
                : 'serviceData-item'
            "
            class="serviceItem-01"
          >
            <span>邀评率</span>
            <span v-if="serviceData.inviteComments" class="item-value"
              >{{ (serviceData.inviteComments * 100).toFixed(2) }}%</span
            >
          </div>
          <div
            :class="
              serviceData.orderErrorRate
                ? 'serviceData-itemBig'
                : 'serviceData-item'
            "
            class="serviceItem-02"
          >
            <span>工单错误率</span>
            <span v-if="serviceData.orderErrorRate" class="item-value"
              >{{ (serviceData.orderErrorRate * 100).toFixed(2) }}%</span
            >
          </div>
          <div
            :class="
              serviceData.firsrTime ? 'serviceData-itemBig' : 'serviceData-item'
            "
            class="serviceItem-03"
          >
            <span>首响均值</span>
            <span v-if="serviceData.firsrTime" class="item-value"
              >{{ serviceData.firsrTime }}s</span
            >
          </div>
          <div
            :class="
              serviceData.qaRate ? 'serviceData-itemBig' : 'serviceData-item'
            "
            class="serviceItem-04"
          >
            <span>问答比</span>
            <span v-if="serviceData.qaRate" class="item-value"
              >{{ (serviceData.qaRate * 100).toFixed(2) }}%</span
            >
          </div>
          <div
            :class="
              serviceData.qualityErrorRate
                ? 'serviceData-itemBig'
                : 'serviceData-item'
            "
            class="serviceItem-05"
          >
            <span>质检错误率</span>
            <span v-if="serviceData.qualityErrorRate" class="item-value"
              >{{ (serviceData.qualityErrorRate * 100).toFixed(2) }}%</span
            >
          </div>
          <div
            :class="
              serviceData.satisfaction
                ? 'serviceData-itemBig'
                : 'serviceData-item'
            "
            class="serviceItem-06"
          >
            <span>服务满意度</span>
            <span v-if="serviceData.missFeedback" class="item-value">{{
              serviceData.satisfaction
            }}</span>
          </div>
          <div
            :class="
              serviceData.serviceHighVoltage
                ? 'serviceData-itemBig'
                : 'serviceData-item'
            "
            class="serviceItem-07"
          >
            <span>服务高压线</span>
            <span v-if="serviceData.serviceHighVoltage" class="item-value">{{
              serviceData.serviceHighVoltage
            }}</span>
          </div>
          <div
            :class="
              serviceData.avgTime ? 'serviceData-itemBig' : 'serviceData-item'
            "
            class="serviceItem-08"
          >
            <span>平响均值</span>
            <span v-if="serviceData.avgTime" class="item-value"
              >{{ serviceData.avgTime }}s</span
            >
          </div>
          <div
            :class="
              serviceData.missFeedback
                ? 'serviceData-itemBig'
                : 'serviceData-item'
            "
            class="serviceItem-09"
          >
            <span>遗漏客诉</span>
            <span v-if="serviceData.missFeedback" class="item-value">{{
              serviceData.missFeedback
            }}</span>
          </div>
          <div
            :class="
              serviceData.missFeedback
                ? 'serviceData-itemBig'
                : 'serviceData-item'
            "
            class="serviceItem-10"
          >
            <span>散客满意度</span>
            <span v-if="serviceData.missFeedback" class="item-value">{{
              serviceData.missFeedback
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="calcuShow" class="common-grey-line"></div>
    <div v-if="calcuShow" class="incomeCal">
      <div class="modu-title">收入预估测算</div>
      <div class="incomeCal-content">
        <div class="incomeCal-item">
          <span>当月税前薪资</span>
          <span class="incomeCal-num" style="color: #1890ff"
            >{{
              (
                Number(calculate.basePay || 0) -
                Number(calculate.deductMoney || 0) +
                Number(calculate.bountyMoney || 0) +
                Number(calculate.subsidyMoney || 0) +
                Number(achievements.achievements || 0)
              ).toFixed(2)
            }}元</span
          >
        </div>
        <div class="incomeCal-item">
          <span>当前底薪</span>
          <span class="incomeCal-num"
            >{{ Number(calculate.basePay || 0).toFixed(2) }}元</span
          >
        </div>
        <div class="incomeCal-item">
          <span>当月绩效提成</span>
          <span class="incomeCal-num"
            >{{ Number(achievements.achievements || 0).toFixed(2) }}元</span
          >
        </div>
        <div class="incomeCal-item">
          <span>本月已有扣款</span>
          <!-- <span class="incomeCal-num" style="color: #52C419">{{calculate.deductMoney || 0}}元</span> -->
          <el-popover
            placement="left"
            title="扣款明细"
            width="auto"
            trigger="hover"
          >
            <table class="hover-table">
              <tr>
                <th class="hover-th">扣款项目</th>
                <th class="hover-th">扣款日期</th>
                <th class="hover-th">扣款金额</th>
              </tr>
              <tr v-for="(item, index) in priceShowData.list1" :key="index">
                <td class="hover-td">{{ item.wageName }}</td>
                <td class="hover-td">{{ item.checkDate }}</td>
                <td class="hover-td">
                  {{ Number(item.calculatePrice || 0).toFixed(2) }}元
                </td>
              </tr>
              <tr v-if="!priceShowData.list1 || !priceShowData.list1.length">
                <td colspan="3" style="text-align:center" class="hover-td">
                  暂无数据
                </td>
              </tr>
            </table>

            <span
              slot="reference"
              class="incomeCal-num"
              style="color: #52C419;cursor: pointer;text-decoration: underline;"
              >{{ Number(calculate.deductMoney || 0).toFixed(2) }}元</span
            >
          </el-popover>
        </div>
        <div class="incomeCal-item">
          <span>当月已有奖励</span>
          <!-- <span class="incomeCal-num" style="color: #E83636">{{calculate.bountyMoney || 0}}元</span> -->
          <el-popover
            placement="left"
            title="奖励明细"
            width="auto"
            trigger="hover"
          >
            <table class="hover-table">
              <tr>
                <th class="hover-th">奖励项目</th>
                <th class="hover-th">奖励日期</th>
                <th class="hover-th">奖励金额</th>
              </tr>
              <tr v-for="(item, index) in priceShowData.list2" :key="index">
                <td class="hover-td">{{ item.wageName }}</td>
                <td class="hover-td">{{ item.checkDate }}</td>
                <td class="hover-td">
                  {{ Number(item.calculatePrice || 0).toFixed(2) }}元
                </td>
              </tr>
              <tr v-if="!priceShowData.list2 || !priceShowData.list2.length">
                <td colspan="3" style="text-align:center" class="hover-td">
                  暂无数据
                </td>
              </tr>
            </table>

            <span
              slot="reference"
              class="incomeCal-num"
              style="color: #e83636;cursor: pointer;text-decoration: underline;"
              >{{ Number(calculate.bountyMoney || 0).toFixed(2) }}元</span
            >
          </el-popover>
        </div>
        <div class="incomeCal-item">
          <span>补贴</span>
          <!-- <span class="incomeCal-num" style="color: #FD854F">{{calculate.subsidyMoney || 0}}元</span> -->
          <el-popover
            placement="left"
            title="补贴明细"
            width="auto"
            trigger="hover"
          >
            <table class="hover-table">
              <tr>
                <th class="hover-th">补贴项目</th>
                <th class="hover-th">补贴日期</th>
                <th class="hover-th">补贴金额</th>
              </tr>
              <tr v-for="(item, index) in priceShowData.list3" :key="index">
                <td class="hover-td">{{ item.wageName }}</td>
                <td class="hover-td">{{ item.checkDate }}</td>
                <td class="hover-td">
                  {{ Number(item.calculatePrice || 0).toFixed(2) }}元
                </td>
              </tr>
              <tr v-if="!priceShowData.list3 || !priceShowData.list3.length">
                <td colspan="3" style="text-align:center" class="hover-td">
                  暂无数据
                </td>
              </tr>
            </table>

            <span
              slot="reference"
              class="incomeCal-num"
              style="color: #FD854F;cursor: pointer;text-decoration: underline;"
              >{{ Number(calculate.subsidyMoney || 0).toFixed(2) }}元</span
            >
          </el-popover>
        </div>
      </div>
      <div class="incomeCal-content">
        <div class="incomeCal-item">
          <span>协议咨询量</span>
          <span class="incomeCal-num" style="color: 1890ff"
            >{{ calculate.agreementInquiries || 0 }}个</span
          >
        </div>
        <div class="incomeCal-item">
          <span>实际咨询量</span>
          <span class="incomeCal-num" style="color: 1890ff"
            >{{ calculate.actualReceptionNum || 0 }}个</span
          >
        </div>
        <div class="incomeCal-item">
          <span>绩效完成率</span>
          <span class="incomeCal-num" style="color: 1890ff">{{
            calculate.performanceCompletionRate || 0
          }}</span>
        </div>
        <div class="incomeCal-item">
          <span>当月实际产能</span>
          <span
            @click="
              () => {
                openDialog(adminId);
              }
            "
            class="incomeCal-num"
            style="color: #1890ff;cursor: pointer;text-decoration: underline;"
            >{{ achievements.monthCap || 0 }}元</span
          >
        </div>
        <div class="incomeCal-item">
          <span>当月产能变化</span>
          <span class="incomeCal-num" style="color: 1890ff"
            >{{ achievements.changeValue || 0 }}元</span
          >
        </div>
      </div>
    </div>
    <div class="common-grey-line"></div>
    <div class="incomeCal">
      <div class="modu-title">相关绩效</div>
      <el-table
        :data="[achievements]"
        border
        style="width: 100%;margin-top: 32px"
      >
        <el-table-column
          prop="performanceName"
          label="绩效名称"
        ></el-table-column>
        <el-table-column
          prop="sumCapacity"
          label="个人目标产值"
        ></el-table-column>
        <el-table-column label="开始时间">
          <template>
            <div>{{ params.startDate }}</div>
          </template>
        </el-table-column>
        <el-table-column label="结束时间">
          <template>
            <div>{{ params.endDate }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="basicCommission" label="提成比例">
          <template slot-scope="scope">
            <div v-if="scope.row.basicCommission">
              {{ (scope.row.basicCommission * 100).toFixed(2) }}%
            </div>
            <div v-else>--</div>
          </template>
        </el-table-column>
        <el-table-column prop="advancedCommission" label="超额奖励">
          <template slot-scope="scope">
            <div v-if="scope.row.advancedCommission">
              {{ (scope.row.advancedCommission * 100).toFixed(2) }}%
            </div>
            <div v-else>--</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- <el-dialog v-dialogDrag title="编辑个人资料" :append-to-body="true" :visible.sync="editVisible" width="520px" :close-on-click-modal="false"> -->
    <a-modal
      title="编辑个人资料"
      :visible="editVisible"
      :confirm-loading="confirmLoading"
      @ok="
        () => {
          updateForm();
        }
      "
      @cancel="
        () => {
          editVisible = false;
        }
      "
      okText="提交"
      cancelText="取消"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="120px"
      >
        <el-form-item label="用户头像：">
          <div class="icon-container">
            <img
              v-if="ruleForm.icon"
              class="iconPeople"
              :src="ruleForm.icon"
              alt=""
            />
            <i v-else class="el-icon-user iconPeople"></i>
            <input
              class="common-upload-input"
              @input="chooseFile"
              type="file"
            />
          </div>
        </el-form-item>
        <el-form-item label="用户名称：">
          <span>{{ ruleForm.nickName }}</span>
        </el-form-item>
        <el-form-item label="员工账号：">
          <span>{{ ruleForm.account }}</span>
        </el-form-item>
        <el-form-item label="微信号：" prop="wxId">
          <el-input
            class="common-screen-input_85"
            placeholder="请输入微信号"
            v-model="ruleForm.wxId"
          ></el-input>
        </el-form-item>
        <el-form-item label="角色：">
          <span>{{ ruleForm.rolesName }}</span>
        </el-form-item>
        <el-form-item label="部门：" prop="userDepartId">
          <a-cascader
            class="common-screen-input_85"
            @change="onChange"
            :fieldNames="{ label: 'name', value: 'id', children: 'child' }"
            :allowClear="false"
            v-model="ruleForm.userDepartId"
            :options="selectDepart"
            placeholder="请选择"
            change-on-select
          />
        </el-form-item>
        <el-form-item label="性别：" prop="sex">
          <div>
            <el-radio v-model="ruleForm.sex" label="1">男</el-radio>
            <el-radio v-model="ruleForm.sex" label="0">女</el-radio>
          </div>
        </el-form-item>
        <el-form-item label="生日：" prop="birthDay">
          <el-date-picker
            v-model="ruleForm.birthDay"
            value-format="yyyy-MM-dd"
            type="date"
            class="common-screen-input_85"
            placeholder="请选择生日"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="地址：" prop="addressId">
          <el-cascader
            :props="props"
            v-model="ruleForm.addressId"
            style="width: 30%;margin-right: 2%"
            clearable
            placeholder="省市区"
          ></el-cascader>
          <el-input
            v-model="ruleForm.address"
            style="width: 53%"
            placeholder="请输入具体地址"
          ></el-input>
        </el-form-item>
        <el-form-item label="银行卡号：" prop="bankCard">
          <el-input
            class="common-screen-input_85"
            placeholder="请输入银行卡号"
            v-model="ruleForm.bankCard"
          ></el-input>
        </el-form-item>
      </el-form>
      <!-- <span slot="footer" class="dialog-footer">
                <el-button class="common-screen-btn" @click="editVisible = false" >取 消</el-button>
                <el-button class="common-screen-btn" type="primary">确 定</el-button>
            </span> -->
      <!-- </el-dialog> -->
    </a-modal>

    <!-- 产值弹 -->
    <el-dialog
      v-dialogDrag
      title="个人月产值明细"
      :visible.sync="dialogVisible"
      width="50%"
      append-to-body
      :before-close="handleClose">
      <el-table
      v-loading="loading2"
      element-loading-text="数据较多，拼命加载中..."
      :data="dialogData.records"
      style="width: 100%">
        <el-table-column
          prop="checkDate"
          :formatter="tableColumn"
          label="交班日期">
        </el-table-column>
        <el-table-column
          prop="shopNum"
          :formatter="tableColumn"
          label="服务店铺数量（个）">
        </el-table-column>
        <el-table-column
          prop="subNum"
          :formatter="tableColumn"
          label="子账号数量（个）">
        </el-table-column>
        <el-table-column
          prop="receptNum"
          :formatter="tableColumn"
          label="交班咨询量（个）">
        </el-table-column>
        <el-table-column
          label="产能（元）">
          <template slot-scope="scope">
            <el-popover
              placement="left"
              title="个人日产值明细"
              width="auto"
              trigger="click"
              @show="showDay(scope.row.shiftId)"
              @hide="clearDayParams"
            >
            <el-button slot="reference" type="text">{{ scope.row.capacity || "--" }}</el-button>
            
            <el-table
              v-loading="loading3"
              border
              element-loading-text="数据较多，拼命加载中..."
              :data="dayData"
              class="table3">
                <el-table-column
                  prop="shopName"
                  show-overflow-tooltip
                  :formatter="tableColumn"
                  label="店铺名称">
                </el-table-column>
                <el-table-column
                  prop="subaccountName"
                  show-overflow-tooltip
                  :formatter="tableColumn"
                  label="子账号名称">
                </el-table-column>
                <el-table-column
                  prop="dayCapacity"
                  show-overflow-tooltip
                  :formatter="tableColumn"
                  label="子账号单日产能">
                  <template slot="header" slot-scope="{ column }">    
                    <el-tooltip class="item" effect="dark" content="子账号单日产能：店铺金额/分配子账号天数/单日店铺分配子账号数" placement="bottom">                
                    <div>
                      <span>{{column.label}}</span>
                    <img
                      src="../../assets/images/question.png"
                      class="tit-img"
                    />
                    </div>
                    </el-tooltip>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="subCapacity"
                  show-overflow-tooltip
                  :formatter="tableColumn"
                  label="子账号总产能">
                  <template slot="header" slot-scope="{ column }">    
                    <el-tooltip class="item" effect="dark" content="子账号总产能：店铺金额/分配子账号天数*客服带号子账号天数" placement="bottom">                
                    <div>
                      <span>{{column.label}}</span>
                    <img
                      src="../../assets/images/question.png"
                      class="tit-img"
                    />
                    </div>
                    </el-tooltip>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="actualReceptionNum"
                  show-overflow-tooltip
                  :formatter="tableColumn"
                  label="交班咨询量">
                  <template slot="header" slot-scope="{ column }">    
                    <el-tooltip class="item" effect="dark" content="交班咨询量：当月客服交班填写的实际咨询量累计" placement="bottom">                
                    <div>
                      <span>{{column.label}}</span>
                    <img
                      src="../../assets/images/question.png"
                      class="tit-img"
                    />
                    </div>
                    </el-tooltip>
                  </template>
                </el-table-column>
                <el-table-column
                  :formatter="tableColumn"
                  show-overflow-tooltip
                  label="子账号服务时间">
                  <template slot="header" slot-scope="{ column }">    
                    <el-tooltip class="item" effect="dark" content="当月该子账号的服务时段" placement="bottom">                
                    <div>
                      <span>{{column.label}}</span>
                    <img
                      src="../../assets/images/question.png"
                      class="tit-img"
                    />
                    </div>
                    </el-tooltip>
                  </template>
                  <template slot-scope="scope">
                    <span>{{ (scope.row.startTime? scope.row.startTime:'--') + '\n' + (scope.row.endTime? scope.row.endTime:'--')}}</span>
                
                  </template>
                </el-table-column>
            </el-table>
              <br />
              <el-pagination
                @size-change="(e) => pageFun3(e, 'pageSize')"
                @current-change="(e) => pageFun3(e)"
                style="text-align: center"
                :page-sizes="pagination3.pageSizes"
                :page-size="dayParams.pageSize"
                :current-page="dayParams.page"
                layout="total, prev, pager, next,sizes, jumper"
                :total="pagination3.total"
              >
              </el-pagination>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <br />
      <el-pagination
        @size-change="(e) => pageFun2(e, 'pageSize')"
        @current-change="(e) => pageFun2(e)"
        style="text-align: center"
        :page-sizes="pagination2.pageSizes"
        :page-size="dialogParams.pageSize"
        :current-page="dialogParams.page"
        layout="total, prev, pager, next,sizes, jumper"
        :total="pagination2.total"
      >
      </el-pagination>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  commonDepartAll,
  sysArea,
  commonAllRole,
  userData
} from "../../service/common.js";
import { portraitDetail, priceDetail, capacityDetail,capacityDayDetail } from "../../service/portrait.js";
import { getSectionDate, Config,tableColumn } from "../../utils/index.js";
import { companyInfo, personUpdate } from "../../service/login.js";
import { Cascader, Modal } from "ant-design-vue";
import { upload } from "../../service/upload.js";
import Vue from "vue";
Vue.use(Modal);
export default {
  components: { aCascader: Cascader, aModal: Modal },
  props: ["userId"],
  data() {
    return {
      tableColumn,
      confirmLoading: false,
      editVisible: false,
      calcuShow: true,
      params: {
        startDate: "",
        endDate: ""
      },
      tableData: [],
      detailInfo: {},
      achievements: {},
      attendance: {},
      calculate: {},
      serviceData: {},
      adminId: this.$sto.get(Config.constants.userInfo).user_id,
      companyId: this.$sto.get(Config.constants.userInfo).company_id,
      ruleForm: {
        nickName: "",
        sex: "",
        address: "",
        account: "",
        birthDay: "",
        icon: "",
        userDepartId: "",
        bankCard: "",
        addressId: [],
        wxId: "",
        rolesName: ""
      },
      rules: {},
      selectDepart: [], // 部门下拉
      props: {
        //地区
        lazy: true,
        lazyLoad: async (node, resolve) => {
          // this.loading = true;
          const { level, value } = node;
          let typeId;
          if (level == 0) {
            typeId = 0;
          } else {
            typeId = value;
          }
          let resData = (await sysArea({ id: typeId })).data;
          let nodes = resData.map((item) => ({
            value: item.id + "",
            label: item.name,
            leaf: level >= 2
          }));
          // this.loading = false;
          resolve(nodes);
        }
      },
      comDetails: {},
      pickerOptions: {
        // 禁用以后的时间
        disabledDate: (time) => {
          return time.getTime() > new Date().getTime();
        }
      },
      currentMonth: {}, // 当月数据
      priceShowData: {
        list1: [],
        list2: [],
        list3: []
      },
      priceShowDataCurrent: {
        list1: [],
        list2: [],
        list3: []
      },
      loading2: false,
      loading3: false,
      dialogVisible: false,
      dialogParams: {
        page: 1,
        pageSize: 10
      },
      pagination2: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100]
      },
      dialogData: {},
      dayParams: {
        page: 1,
        pageSize: 10
      },
      pagination3: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100]
      },
      dayData: [],
    };
  },
  mounted() {
    // this.params = this.defaultDate(new Date)
    if (this.$route.query.startDate && this.$route.query.endDate) {
      this.params = {
        startDate: this.$route.query.startDate,
        endDate: this.$route.query.endDate
      };
    } else {
      this.params = {
        startDate: getSectionDate("lastmonth")[0],
        endDate: getSectionDate("lastmonth")[1]
      };
    }
    this.portraitCurrent();
    this.portraitDetail();
    this.commonDepartAll(this.companyId);
    this.getDetails();
  },
  methods: {
    dateYM(date) {
      // 截取年月
      const newArr = date.split("-");
      return `${newArr[0]}-${newArr[1]}`;
    },
    async priceDetail(type) {
      // 扣款-奖励-补贴
      const { data } = await priceDetail({
        checkDate: this.dateYM(this.params.startDate),
        type,
        adminId: this.userId
      });
      this.priceShowData[`list${type}`] = data || [];
    },
    async priceDetailCurrent(type, startDate) {
      // 扣款-奖励-补贴
      const { data } = await priceDetail({
        checkDate: this.dateYM(startDate),
        type,
        adminId: this.userId
      });
      this.priceShowDataCurrent[`list${type}`] = data || [];
    },
    async chooseFile(e) {
      let file = e.target.files[0];
      let format = file.name.split(".")[file.name.split(".").length - 1];
      if (file.size / 1024 > 1024 * 50) {
        e.target.value = "";
        return this.$message.error("文件大小不能超过50M");
      }
      if (!Config.fileType.imgType.includes(format)) {
        e.target.value = "";
        return this.$message.error(
          `只能上传 ${Config.fileMessage.imgType} 格式的文件`
        );
      }
      let { data } = await upload({ file: file });
      // await personUpdate({ id: this.comDetails.id,icon: data.filename });
      e.target.value = "";
      this.ruleForm.icon = data.filename;
      // this.$vuex.commit('getUserIcon', data.filename)
      // this.$sto.set(Config.constants.userInfo, this.userInfo);
    },
    onChange(value) {
      this.$set(this.ruleForm, "userDepartId", value);
      this.$forceUpdate();
    },
    async updateForm() {
      // 修改
      let ruleForm = { ...this.ruleForm };
      if (ruleForm && ruleForm.addressId) {
        ruleForm.addressId = ruleForm.addressId.join(",");
      }
      if (ruleForm && ruleForm.userDepartId && ruleForm.userDepartId.length) {
        ruleForm.userDepartId = Number(
          ruleForm.userDepartId[ruleForm.userDepartId.length - 1]
        );
      }
      this.confirmLoading = true;
      const { code } = await personUpdate(ruleForm);
      // 调info接口刷新角色
      let { data } = await userData();
      if (data) {
        this.$vuex.commit("getUserInfo", data);
        this.$vuex.commit("getUserIcon", data.icon);
      }
      if (code == 200) {
        this.$message.success("修改成功");
      }
      this.portraitDetail();
      this.confirmLoading = false;
      this.editVisible = false;
    },
    async getDetails() {
      this.loading = true;
      this.fileList = [];
      const { data } = await companyInfo();
      // let addressId = data.address_id
      let rolesName;
      // if (data && addressId) { // 地区
      //     let id = addressId.split(",")[addressId.split(",").length - 1]
      //     const { data: res } = await sysAreaDetail({ id })
      //     let addressName = res
      // }
      if (data && data.roleId) {
        //  角色
        let resData = (await commonAllRole({ companyId: this.companyId })).data;
        resData.forEach((item) => {
          if (item.id == data.roleId) {
            rolesName = item.name;
          }
        });
      }
      this.ruleForm = {
        id: data.id,
        STATUS: data.STATUS,
        manager_flag: data.manager_flag,
        nickName: data.nick_name,
        sex: data.sex == 0 ? "0" : "1",
        address: data.address,
        account: data.account,
        birthDay: data.birth_day,
        icon: data.icon,
        userDepartId: data.user_depart_id,
        bankCard: data.bank_card,
        addressId: data.address_id ? data.address_id.split(",") : [],
        wxId: data.wx_id,
        rolesName: rolesName,
        companyId: data.company_id,
        companyName: data.company_name,
        createId: data.create_id,
        createTime: data.create_time,
        isDeleted: data.is_deleted,
        joinTime: data.join_time,
        passWord: data.pass_word,
        updateId: data.update_id,
        updateTime: data.update_time,
        userDepartName: data.user_depart_name,
        haveCompany: data.have_company
      };
      this.comDetails = data;
      this.findParent(data.user_depart_id, this.selectDepart); // 部门
      this.loading = false;
    },
    findParent(childrenId, arr, path) {
      // 递归父id
      if (path === undefined) {
        path = [];
      }
      for (let i = 0; i < arr.length; i++) {
        let tmpPath = path.concat();
        tmpPath.push(arr[i].id);
        if (childrenId == arr[i].id) {
          return (this.ruleForm.userDepartId = tmpPath);
        }
        if (arr[i].child) {
          let findResult = this.findParent(childrenId, arr[i].child, tmpPath);
          if (findResult) {
            return (this.ruleForm.userDepartId = findResult);
          }
        }
      }
    },
    async commonDepartAll(id) {
      // 部门 下拉
      let { data } = await commonDepartAll({}, id);
      this.selectDepart = data || [];
    },
    changeDate(val) {
      this.params = this.defaultDate(val);
      this.portraitDetail();
    },
    defaultDate(date) {
      // 月份筛选
      let nowDate = new Date(date);
      let year = nowDate.getFullYear();
      let month = nowDate.getMonth() + 1;
      let dateAfter = new Date(year, month, 0).getDate();
      return {
        startDate: `${year}-${month < 10 ? "0" + month : month}-01`,
        endDate: `${year}-${month < 10 ? "0" + month : month}-${dateAfter}`
      };
    },
    async portraitDetail() {
      let params = { ...this.params };
      params.adminId = this.userId;
      let { data } = await portraitDetail(params);
      this.detailInfo = data || {};
      this.achievements = data.achievements || {};
      this.calculate =
        data.calculate &&
        data.calculate.allList &&
        data.calculate.allList.length
          ? data.calculate.allList[0]
          : {};
      this.serviceData = data.serviceData || {};
      this.attendance = data.attendance || {};
      this.priceDetail(1);
      this.priceDetail(2);
      this.priceDetail(3);
    },
    async portraitCurrent() {
      // 员工画像
      const params = {
        adminId: this.userId,
        startDate: getSectionDate("month")[0],
        endDate: getSectionDate("month")[1]
      };
      const { data } = await portraitDetail(params);
      this.currentMonth = {
        basePay:
          data.calculate && data.calculate.allList && data.calculate.allList.length && data.calculate.allList[0].basePay ? data.calculate.allList[0].basePay : 0,
        deductMoney:
          data.calculate && data.calculate.allList && data.calculate.allList.length && data.calculate.allList[0].deductMoney ? data.calculate.allList[0].deductMoney : 0,
        bountyMoney:
          data.calculate && data.calculate.allList && data.calculate.allList.length && data.calculate.allList[0].bountyMoney ? data.calculate.allList[0].bountyMoney : 0,
        subsidyMoney:
          data.calculate && data.calculate.allList && data.calculate.allList.length && data.calculate.allList[0].subsidyMoney ? data.calculate.allList[0].subsidyMoney : 0,
        achievements:
          data.achievements && data.achievements.achievements
            ? data.achievements.achievements
            : 0
      };
      this.priceDetailCurrent(1, params.startDate);
      this.priceDetailCurrent(2, params.startDate);
      this.priceDetailCurrent(3, params.startDate);
    },
    openDialog(id){
      this.dialogVisible = true
      this.dialogParams.adminId = id
      let date = this.params.startDate.split("-")
      this.dialogParams.checkDate = date[0]+'-'+date[1]
      this.getDialogData();
    },
    async getDialogData(){ //月产值
      let params = {...this.dialogParams}
      this.loading2 = true
      const { data } = await capacityDetail(params)
      this.loading2 = false
      this.dialogData = data 
      this.pagination2.total = data.total
    },
    pageFun3(e, type) {
      // 分页
      if (type == "pageSize") {
        this.dayParams.pageSize = e;
      } else {
        this.dayParams.page = e;
      }
      if(this.dayParams.shiftId){
        this.showDay(this.dayParams.shiftId);
      }
    },
    async showDay(id) { //日产值
      if(id){
        this.dayParams.shiftId = id
      }
      let params = {...this.dayParams}
      this.loading3 = true
      const { data } = await capacityDayDetail(params)
      this.loading3 = false
      if(data.records&&data.records.length){
        this.dayData = data.records
      }
      this.pagination3.total = data.total
    },
    handleClose() {
      this.dialogVisible = false
      this.dialogParams = {
        page: 1,
        pageSize: 10
      }
    },
    clearDayParams(){
      this.dayParams.page = 1
      this.dayParams.pageSize = 10
    }
  }
};
</script>
<style lang="less">
.is-light {
  border-color: #1890ff !important;
  color: #1890ff !important;
}
.el-popover {
  padding: 0;
}
.el-popover__title {
  padding: 12px 16px;
  box-sizing: border-box;
  font-size: 14px;
  border-bottom: 1px solid #f7f7f7;
}
.hover-table {
  width: 460px;
  font-size: 14px;
  margin: 0 12px 16px;
  border-collapse: collapse;
  .hover-td,
  .hover-th {
    padding: 4px 8px;
    border: 1px solid #eee;
  }
}
</style>
<style lang="less" scoped>
.icon-container {
  width: 48px;
  height: 48px;
  position: relative;
  .choose-input {
    width: 100%;
    height: 100%;
    font-size: 0;
    opacity: 0;
    top: 0;
    left: 0;
  }
}
.iconPeopleDetail {
  border: 1px solid rgba(153, 153, 153, 100);
  border-radius: 50%;
  overflow: hidden;
  width: 120px;
  height: 120px;
  font-size: 120px;
}
.iconPeople {
  width: 100%;
  height: 100%;
  color: #fff;
  background: #87d068;
  border-radius: 50%;
  font-size: 38px;
  line-height: 48px;
  text-align: center;
  cursor: pointer;
}
.personalDetail-container {
  text-align: left;
  width: 100%;
  .el-icon-edit-outline {
    width: 32px;
    height: 32px;
    font-size: 20px;
    color: #999;
    border: 1px solid #999;
    border-radius: 50%;
    text-align: center;
    line-height: 32px;
    cursor: pointer;
    &:hover {
      border-color: #1890ff;
      color: #1890ff;
    }
  }
  .tableList {
    width: 100%;
    margin-top: 16px;
    tr {
      height: 48px;
      line-height: 48px;
      font-size: 14px;
      text-align: center;
      th {
        background: #fdefef;
        color: #e83636;
        font-weight: 500;
        border: 1px solid #eee;
      }
      td {
        border: 1px solid #eee;
      }
    }
  }
  .attendance-top {
    margin: 32px 0;
    width: 100%;
    height: 128px;
    background: #f7f7f7;
    display: flex;
    justify-content: space-between;
    .attendance-item {
      display: flex;
      flex-direction: column;
      padding: 32px 24px;
      justify-content: space-between;
      font-size: 14px;
      color: #333;
      .item-name {
        font-size: 28px;
        color: #1890ff;
      }
    }
  }
  .incomeCal {
    padding: 24px 16px;
    box-sizing: border-box;
    .incomeCal-content {
      height: 132px;
      width: 100%;
      background: #f7f7f7;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      margin-top: 32px;
      .incomeCal-item {
        height: 100%;
        box-sizing: border-box;
        padding: 32px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: #333;
        font-size: 14px;
        .incomeCal-num {
          font-size: 28px;
          color: #333;
        }
      }
    }
  }
  .modu-title {
    font-size: 20px;
    height: 28px;
    line-height: 28px;
    padding-left: 16px;
    box-sizing: border-box;
    color: #333;
    position: relative;
    &::after {
      content: " ";
      width: 4px;
      height: 100%;
      position: absolute;
      background: #1890ff;
      border-radius: 2px;
      left: 0;
      top: 0;
    }
  }
  .attendance,
  .serviceData {
    width: 50%;
    box-sizing: border-box;
  }
  .attendance {
    padding: 24px 32px 24px 16px;
    max-height: 480px;
    overflow-y: auto;
  }
  .serviceData {
    border-left: 16px solid #eee;
    padding: 24px 16px 24px 32px;
    .service-data {
      width: 100%;
      height: 360px;
      border-radius: 4px;
      margin-top: 16px;
      border: 1px solid #eee;
      position: relative;
      font-size: 14px;
      .item-value {
        font-size: 28px;
      }
      .serviceData-item {
        width: 100px;
        height: 100px;
        color: #fff;
        background: #6bb8ff;
        border-radius: 50%;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        padding: 16px 0;
        box-sizing: border-box;
      }
      .serviceData-itemBig {
        width: 120px;
        height: 120px;
        color: #fff;
        background: #1890ff;
        border-radius: 50%;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        padding: 16px 0;
        box-sizing: border-box;
      }
      .serviceItem-01 {
        top: 56px;
        left: 24px;
      }
      .serviceItem-02 {
        top: 24px;
        left: 154px;
      }
      .serviceItem-03 {
        top: 55px;
        left: 293px;
      }
      .serviceItem-04 {
        top: 46px;
        left: 520px;
      }
      .serviceItem-05 {
        top: 231px;
        left: 48px;
      }
      .serviceItem-06 {
        top: 152px;
        left: 154px;
      }
      .serviceItem-07 {
        top: 209px;
        left: 278px;
      }
      .serviceItem-08 {
        top: 186px;
        left: 429px;
      }
      .serviceItem-09 {
        top: 226px;
        left: 574px;
      }
      .serviceItem-10 {
        top: 126px;
        left: 646px;
      }
    }
  }
  .work-info {
    display: flex;
    height: 64px;
    color: #333;
    font-size: 28px;
    align-items: center;
    font-weight: 700;
  }
  .personal-container {
    width: 100%;
    display: flex;
    padding: 40px 32px;
    box-sizing: border-box;
    .info-right {
      flex: 1;
      overflow: auto;
      padding-left: 90px;
      box-sizing: border-box;
      .infoItem-container {
        width: 100%;
        margin: 10px 0 0;
        display: flex;
        justify-content: space-between;
        .info-item {
          width: 160px;
          height: 90px;
          display: flex;
          flex-direction: column;
          padding: 15px;
          box-sizing: border-box;
          background: #f7f7f7;
          border-radius: 4px;
          justify-content: space-between;
          font-size: 14px;
          color: #666;
          .item-num {
            color: #333;
            font-size: 28px;
          }
        }
        .info-item-btn {
          width: 160px;
          height: 90px;
          display: flex;
          flex-direction: column;
          box-sizing: border-box;
          padding: 24px 15px;
          justify-content: space-between;
          border-radius: 4px;
          color: #1890ff;
          background: #edf6ff;
          align-items: center;
          cursor: pointer;
        }
      }
    }
    .personal-info {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      color: #999;
      align-items: center;
      .info-img {
        width: 120px;
        height: 120px;
        border-radius: 50%;
      }
      .info-name {
        margin: 16px 0;
        font-size: 20px;
        color: #333;
        line-height: 1;
      }
    }
  }
}
</style>
